import { getAsset } from '~/lib/asset';
import { cn } from '~/lib/utils';

export default function Image({
  src,
  alt,
  className,
  imageClassName,
  errorImage,
}: {
  src: string;
  alt: string;
  className?: string;
  imageClassName?: string;
  useLoader?: boolean;
  errorImage?: string;
  isPriority?: boolean;
}) {
  return (
    <div className={className}>
      <img
        src={getAsset(src)}
        alt={alt}
        className={cn('w-full h-full', imageClassName)}
        loading="lazy"
        onError={(e) => {
          if (errorImage) {
            (e.target as HTMLImageElement).src = getAsset(errorImage);
          }
        }}
      />
    </div>
  );
}
